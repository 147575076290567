import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import {ProtectedComponent} from "./login/ProtectedComponent";
import {NotAuthenticatedUser} from "./login/NotAuthenticatedUser";
import {useAuth} from "react-oidc-context";
import {ErrorBoundary} from "react-error-boundary";
import FallbackError from "./common/FallbackError";


function App() {

    const auth = useAuth();

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div></div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    if(auth.isAuthenticated){
        return (<ErrorBoundary fallbackRender={FallbackError}>
                    <ProtectedComponent/>
                </ErrorBoundary>);
    }

    return <>
        <NotAuthenticatedUser />
    </>;
}

export default App;
