import {Container, Navbar} from "react-bootstrap";
import logo from "../../public/fenixamd.png";
import SignInButton from "./SignInButton";
import {Landing} from "./Landing";
import React, {useState} from "react";
import {getEnvironment} from "../components/Utils";


export const NotAuthenticatedUser = () => {

    const [environment] = useState(getEnvironment());
    const webAPIEnv = environment === 'PROD' ? '' : ('['+environment+']');
    let title = document.getElementById('idWebAPITitle');
    if (title) {
        title.innerText = environment + " - Fenixa";
    }

    return (
        <div className="App">
            <Container>
                <Navbar expand="lg" className="bg-body-tertiary navbar-slim" data-bs-theme="dark" style={{paddingLeft: "10px"}}>
                    <Navbar.Brand href="/">
                        <img src={logo} alt="Fenixa" width={40} height={40} style={{marginRight: "15px"}}/>
                        Fenixa {webAPIEnv}
                    </Navbar.Brand>
                    <div className="collapse navbar-collapse justify-content-end" style={{marginRight: "15px"}}>
                        <SignInButton variant={"outline-light"}/>
                    </div>
                </Navbar>
            </Container>
            <Landing />
        </div>
    )
}