import React from "react";
import {useAuth} from "react-oidc-context";
import {getAuthority} from "../components/Utils";
import {MdLogout} from "react-icons/md";
import {Dropdown} from "react-bootstrap";

/**
 * Renders a sign out button
 */
export const SignOutButton = () => {
    const auth = useAuth();

    const handleLogout = () => {
        auth.removeUser();
        window.location.href = `${getAuthority()}/protocol/openid-connect/logout?id_token_hint=${auth.user?.id_token}&post_logout_redirect_uri=${window.location.origin}/`;
    };

    return (
        <>
            <Dropdown.Item href="#" onClick={() => handleLogout()}>
                <MdLogout/> Sign out
            </Dropdown.Item>
        </>

    );
};