import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    addLoading,
    buildHeadersFromUser,
    compareNotes,
    compressString,
    dateParser,
    getAPIServer, getRoleName,
    handleErrorResponse,
    handleNetworkError,
    removeLoading
} from "./Utils";
import BasicTable from "./BasicTable";
import moment from "moment";
import 'moment/locale/en-gb.js'
import AlertComponent from "../common/AlertComponent";
import {AlertInfo} from "./Types";
import {toast} from "react-toastify";
import {FaEdit} from "react-icons/fa";
import {AgGridReact} from '@ag-grid-community/react';
import {useNavigate} from "react-router";
import {diff as DiffEditor} from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-json";
import {Badge} from "react-bootstrap";
import {MdOutlineDisplaySettings, MdOutlinePersonRemove} from "react-icons/md";
import ConfirmationDialog from "../common/ConfirmationDialog";

import {ModuleRegistry} from '@ag-grid-community/core';
import {MasterDetailModule} from '@ag-grid-enterprise/master-detail';
import {RangeSelectionModule} from '@ag-grid-enterprise/range-selection';
import {useAuth} from "react-oidc-context";
import FallbackError from "../common/FallbackError";
import {ErrorBoundary} from "react-error-boundary"; // Import the RangeSelectionModule


ModuleRegistry.registerModules([ MasterDetailModule, RangeSelectionModule ]);



function buildClientData(clients: any[]) {
    let data: any[] = [];
    data = clients.map((c: any)=>{
        let out = {
            ...c
        };
        if(c.permissions !== '' && c.permissions !== '__EVERYTHING__') {
            out["permissions"] = JSON.parse(c.permissions);
        }else if (c.permissions !== '' && c.permissions === '__EVERYTHING__'){
            out["permissions"] = '__EVERYTHING__';
        }
        if(c.notes) {
            out["notes"] = JSON.parse(c.notes);
        }
        return out;
    });
    return data;
}

function checkAdminWithNoPermissions(rowData: any) {
    return rowData.role.includes('Admin') && (!rowData.permissions || rowData.permissions.length === 0);
}




const dateOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Use 24-hour format
};

const EditPermissionsCellRenderer = (props: any) =>{
    const navigate = useNavigate();
    const userFlag = props.data.isNew && props.data.role.length > 0 ?
        <Badge style={{marginLeft: "10px", cursor: "pointer"}}
               onClick={()=>{
                   navigate(`/?customer=${props.data.name}__${props.data.oid}&connectionType=snapshot&filters=NoXSA&delta=yes&fetchingData=no&sseConnected=no`);
               }}
               bg={"primary"}>
            Add
        </Badge>
        : null;
    return  <div>
        {props.data.role.length > 0 &&
            <FaEdit className={"icon-button"}
                    onClick={()=>{
                        navigate(`/?customer=${props.data.name}__${props.data.oid}&connectionType=snapshot&filters=NoXSA&delta=yes&fetchingData=no&sseConnected=no`);
                    }}/>
        }
        <span>{props.data.name}</span>
        {userFlag}
    </div>
}

function Entitlements() {

    const auth = useAuth();
    const navigate = useNavigate();
    const [clients, setClients] = useState<any[]>([]);
    const [availableClients, setAvailableClients] = useState<any[]>([]);
    const [colDefs, setColDefs] = useState<any[]>([]);
    const gridRef = useRef<AgGridReact>(null);
    const [showConfirmationDeleteUserModal, setShowConfirmationDeleteUserModal] = useState<boolean>(false);
    const [userToRemove, setUserToRemove] = useState<any>(null);




    const [alertInfo, setAlertInfo] = useState<AlertInfo>({
        title: "",
        message: "",
        variant: "success",
        show: false
    });


    useEffect(() => {
        if (auth.isAuthenticated) {
            addLoading();
            const requestHeaders = buildHeadersFromUser();
            fetch(`${getAPIServer()}/fenixa/customers`, {
                method: 'GET',
                headers: requestHeaders,
            })
                .then(response => {
                    if(response.ok) {
                        return response.json();
                    }else{
                        handleErrorResponse(response);
                        return [];
                    }
                })
                .then(data => {
                    setClients(data.map((d: any)=>{
                        return {
                            ...d,
                            role: d.role ? d.role.map((r: string)=>getRoleName(r)) : []
                        }
                    }));
                })
                .finally(()=>{
                    removeLoading();
                })
                .catch((error)=>{
                    handleNetworkError(error);
                });
        }
    }, []);

    const PermissionsCellRenderer = (props: any) =>{
        if(props.value !== '' && props.value === '"__EVERYTHING__"'){
            return <div>
                        <Badge bg={"primary"}>entitled to everything</Badge>
                    </div>
        }
        let permissions = props.value === '' ? [] : JSON.parse(props.value);
        const isAdminWithNoPermissions = checkAdminWithNoPermissions(props.data);
        return !isAdminWithNoPermissions ? (
            <>
                {permissions && permissions.length > 0 && permissions.map((p: any, index: number)=>{
                    return  <div key={`set_${index}`}
                                 className={"cell-renderer-fields-set-box"}>
                        {
                            p.map((f: any, fieldIndex: number)=>{
                                return <div key={`${index}_${fieldIndex}`} style={{width: "100%", position: "relative", display: "flex"}}>
                                        <div style={{display: "inline-block", minWidth: "100px"}}>
                                            <span key={`div_${index}_${f}`} ><b>{f.field}</b></span>
                                        </div>
                                        <div className={"entitlements-permissions-cell"}>
                                            {f.filters["match"].map(((t: string, indexT: number)=>{
                                                return <div key={`match_val_${f}_${t}_${indexT}`} style={{width: "100%"}}>
                                                            <span className={"text-wrap"}>{t}</span>
                                                       </div>;
                                            }))}
                                            {f.filters["regExp"].map(((t: string, indexT: number)=>{
                                                return <div key={`reg_val_${f}_${t}_${indexT}`} style={{width: "100%"}}>
                                                            <span className={"text-wrap"}>{t}</span>
                                                       </div>;
                                            }))}
                                        </div>
                                    </div>
                            })
                        }
                    </div>
                })}
            </>
        ) : <div>
            <Badge className={"badge-button"}
                   onClick={()=> {
                       entitleEverything(props.data.oid, props.data.name);
                   }}
                   bg={"success"}>Click to entitle everything</Badge>
        </div>
    }




    const CustomGroupCellRenderer = (params: any) => {
        const totalNotes = params.data.notes ? params.data.notes.length : 0;
        return totalNotes > 0 ? (
            <div>
                <span>History changes ({totalNotes})</span>
            </div>
        ) : (<span>No changes</span>);
    }

    const RunRequestCellRenderer = (props: any) =>{

        function handleClickRun(){
            let selectedFields: any[] = [];
            const connectionType = "snapshot";
            let url = `/?filters=${compressString(JSON.stringify(selectedFields))}&connectionType=${connectionType}`;
            url += `&sseConnected=no`;
            url += `&customer=${props.data.name}__${props.data.oid}&origin=sub&delta=yes&fetchingData=no`;
            navigate(url);
        }

        return <div className={"text-center"}>
            <MdOutlineDisplaySettings className={"icon-button"} onClick={()=>handleClickRun()}/>
        </div>
    }

    const DeleteUserCellRenderer = (props: any) =>{

        function handleDeleteUser(){
            setUserToRemove(props.data);
            setShowConfirmationDeleteUserModal(true);
        }

        return <div className={"text-center"}>
            <MdOutlinePersonRemove className={"icon-button"} onClick={()=>handleDeleteUser()}/>
        </div>
    }

    function buildColDefsFieldsByUsersPermissions() {
        let colDefs: any[] = [
            {
                cellRenderer: EditPermissionsCellRenderer,
                filter: "agSetColumnFilter",
                filterParams: {
                    defaultToNothingSelected: true,
                },
                maxWidth: 350,
                editable: false,
                floatingFilter: true,
                headerName: "Name",
                valueGetter: (params: any) => {
                    return params.data.name;
                }
            },
            {
                field: "role",
                filter: "agSetColumnFilter",
                filterParams: {
                    defaultToNothingSelected: true,
                },
                editable: false,
                maxWidth: 150,
                floatingFilter: true
            },
            {
                headerName: "Changes",
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    innerRenderer: CustomGroupCellRenderer
                },
                filter: 'agTextColumnFilter',
                valueGetter: (params: any) => {
                    return params.data.notes == null ? '' : JSON.stringify(params.data.notes);
                },
                floatingFilter: true
            },
            {
                field: "permissions",
                cellRenderer: PermissionsCellRenderer,
                filter: 'agTextColumnFilter',
                valueGetter: (params: any) => {
                    return params.data.permissions == null ? '' : JSON.stringify(params.data.permissions);
                },
                editable: false,
                wrapText: true,
                autoHeight: true,
                floatingFilter: true,
                minWidth: 800
            },
            {
                field: "limit",
                filter: 'agTextColumnFilter',
                editable: true,
                floatingFilter: true,
                valueSetter: (params: any) =>{
                  if(!params.newValue || isNaN(params.newValue) || params.newValue < 0 || params.newValue === params.oldValue){
                      return false;
                  }
                  params.data.limit = params.newValue;
                  return true;
                },
                maxWidth: 100
            },
            {
                field: "used",
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                maxWidth: 100
            },
            {
                headerName: "Inspect",
                cellRenderer: RunRequestCellRenderer,
                hide: false,
                lockVisible: true,
                maxWidth: 100
            },
            {
                headerName: "Delete",
                cellRenderer: DeleteUserCellRenderer,
                hide: false,
                lockVisible: true,
                maxWidth: 90
            }
        ];
        return colDefs;
    }

    useEffect(() => {
        if(clients && clients.length > 0){
            setAvailableClients(buildClientData(clients));
            setColDefs(buildColDefsFieldsByUsersPermissions());
        }
    }, [clients]);

    const getRowHeight = useCallback((params: any) => {
        let totalRows = 0;
        if(Array.isArray(params.data.permissions)) {
            params.data.permissions.forEach((r: any) => {
                r.forEach((p: any)=>{
                    totalRows += p.filters.match.length + p.filters.regExp.length;
                })
            });
            return params.data.permissions && params.data.permissions.length > 0 ? (totalRows * 23) + 7 : 40;
        }
        return 40;

    }, [colDefs]);

    const getDetailRowHeight = useCallback((params: any) => {
        const everythingPermissions = {
            entitlement: "Everything"
        };
        const prevPermissions = params.data.prevPermissions && params.data.prevPermissions !== '__EVERYTHING__' ? JSON.parse(params.data.prevPermissions) : everythingPermissions;
        const newPermissions = params.data.newPermissions && params.data.newPermissions !== '__EVERYTHING__' ? JSON.parse(params.data.newPermissions) : everythingPermissions;
        const prevPermsLines = (JSON.stringify(prevPermissions, null, 2).match(/\n/g) || []).length + 1;
        const newPermsLines = (JSON.stringify(newPermissions, null, 2).match(/\n/g) || []).length + 1;
        const maxFields = Math.max(prevPermsLines, newPermsLines);
        return maxFields > 0 ? maxFields * 16 : 40;
    }, [colDefs]);

    const DateRenderer = ( props: any ) => {
        moment.locale('en-gb');
        // Customize the date format based on your requirements
        const formattedDate = dateParser(moment(props.value, 'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate(), dateOptions);
        return <div>{formattedDate}</div>;
    };

    const CustomerJsonDifference = ( props: any ) => {
        // Customize the date format based on your requirements
        const everythingPermissions = {
            entitlement: "Everything"
        };
        const json1 = props.data.prevPermissions !== '__EVERYTHING__' ? JSON.parse(props.data.prevPermissions) : everythingPermissions;
        const json2 = props.data.newPermissions !== '__EVERYTHING__' ? JSON.parse(props.data.newPermissions) : everythingPermissions;

        return <DiffEditor
            value={[JSON.stringify(json1, null, 2), JSON.stringify(json2, null, 2)]}
            theme="github"
            mode={"json"}
            readOnly={true}
            height={"100%"}
            width={"100%"}
            highlightActiveLine={true}
            editorProps={{ $blockScrolling: true }}
        />;
    };

    function removeUser(){
        if(userToRemove){
            addLoading();
            const requestHeaders = buildHeadersFromUser();
            fetch(`${getAPIServer()}/fenixa/user/${userToRemove.oid}`, {
                method: 'DELETE',
                headers: requestHeaders
            })
                .then(response => {
                    if(response.ok) {
                        return response.json();
                    }else{
                        handleErrorResponse(response);
                    }
                })
                .then(data => {
                    if(data) {
                        setClients(data);
                        setUserToRemove(null);
                        toast.success(`User deleted`, {
                            autoClose: 1000
                        });
                    }
                })
                .finally(()=>{
                    removeLoading();
                    setShowConfirmationDeleteUserModal(false);
                })
                .catch((error)=>{
                    handleNetworkError(error);
                });
        }
    }

    function entitleEverything(oid: string, name: string){
        addLoading();
        const requestHeaders = buildHeadersFromUser();
        fetch(`${getAPIServer()}/fenixa/customer/${oid}`, {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify({
                oid: oid,
                name: name,
                entitlement: '__EVERYTHING__',
            })
        })
            .then(response => {
                if(response.ok) {
                    return response.json();
                }else{
                    handleErrorResponse(response);
                }
            })
            .then(data => {
                if(data) {
                    toast.success(`Entitlement everything for customer ${data.name}`, {
                        autoClose: 1000
                    });
                    setAvailableClients(availableClients => availableClients.map(c => {
                        if (c.oid !== data.oid) {
                            return c;
                        } else {
                            return {
                                ...c,
                                isNew: data.isNew,
                                notes: JSON.parse(data.notes),
                                permissions: '__EVERYTHING__'
                            }
                        }
                    }));
                }
            })
            .finally(()=>{
                removeLoading();
            })
            .catch((error)=>{
                handleNetworkError(error);
            });
    }

    function saveCustomerInfo(event: any){
        if(event.data.isNew){
            toast.warning(`Please add ${event.data.name} before assigning limits`);
        }
        else if(event.newValue && event.oldValue !== event.newValue && event.newValue > 0) {
            addLoading();
            const requestHeaders = buildHeadersFromUser();
            fetch(`${getAPIServer()}/fenixa/customer/${event.data.oid}`, {
                method: 'POST',
                headers: requestHeaders,
                body: JSON.stringify({
                    id: event.data.id,
                    oid: event.data.oid,
                    limit: event.newValue,
                })
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleErrorResponse(response);
                    }
                })
                .then(data => {
                    if (data) {
                        toast.success(`Saved limits for customer ${data.name}`, {
                            autoClose: 1000
                        });
                    }
                })
                .finally(() => {
                    removeLoading();
                })
                .catch((error)=>{
                    handleNetworkError(error);
                });
        }
    }


    const gridOptions = {
        getRowHeight: getRowHeight,
        masterDetail: true,
        detailRowAutoHeight: true,
        ref: gridRef,
        selection: {
            mode: "cell"
        },
        ensureDomOrder: true,
        detailCellRendererParams: {
            // provide the Grid Options to use on the Detail Grid
            detailGridOptions: {
                defaultColDef: {
                    flex: 1,
                    resizable: true,
                    sortable: true
                },
                getRowHeight: getDetailRowHeight,
                columnDefs: [
                    { 
                        field: 'date',
                        filter: "agSetColumnFilter",
                        cellRenderer: DateRenderer,
                        floatingFilter: true,
                        maxWidth: 150
                    },
                    { 
                        field: 'user', 
                        filter: "agSetColumnFilter",
                        floatingFilter: true,
                        maxWidth: 200
                    },
                    { 
                        field: 'message',
                        headerName: "Notes",
                        filter: 'agTextColumnFilter',
                        valueGetter: (params: any) => {
                            return params.data.message == null ? '' : params.data.message;
                        },
                        floatingFilter: true
                    },
                    {
                        headerName: "Differences",
                        filter: 'agTextColumnFilter',
                        valueGetter: (params: any) => {
                            return (params.data.newPermissions == null ? '' : JSON.stringify(params.data.newPermissions)) +
                                    params.data.prevPermissions == null ? '' : JSON.stringify(params.data.prevPermissions);
                        },
                        cellRenderer: CustomerJsonDifference,
                        floatingFilter: true,
                        minWidth: 1000
                    }
                ]
            },
            // get the rows for each Detail Grid
            getDetailRowData: (params: any) => {
                params.successCallback(params.data.notes ? params.data.notes.sort(compareNotes) : []);
            }
        },
        onCellEditingStopped: (event: any)=>{
            saveCustomerInfo(event);
        }
    }

    return <ErrorBoundary fallbackRender={FallbackError}>
            <fieldset>
                <AlertComponent
                    alertInfo={alertInfo}
                    handleClose={()=>setAlertInfo({...alertInfo,
                                                            show: false
                    })}
                />
                <BasicTable columns={colDefs} data={availableClients} gridOptions={gridOptions} containerClass={"basic-table-container"}/>
                {
                    showConfirmationDeleteUserModal &&
                    userToRemove &&
                    <ConfirmationDialog message={`Are you sure to remove ${userToRemove.name}?`}
                                        show={showConfirmationDeleteUserModal}
                                        handleClose={()=>setShowConfirmationDeleteUserModal(false)}
                                        handleConfirm={()=>removeUser()}/>
                }
           </fieldset>
    </ErrorBoundary>
}

export default Entitlements;
